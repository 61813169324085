import React, { useEffect, useRef, useState } from "react";
import LeftBox from "../components/LeftBox";
//import RightBox from "./RightBox";
import MetaTags from "react-meta-tags";
import Fade from "react-reveal/Fade";
import Particles from "react-particles-js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGithub, faLinkedin } from "@fortawesome/free-brands-svg-icons";

export const LandingPage = () => {
  const element = useRef(null);

  /*   useEffect(() => {
    window.addEventListener("deviceorientation", e => {
      let tiltLR = e.gamma;
      let tiltFB = e.beta;

      console.log(tiltLR);
      console.log(tiltFB);

      element.current.style.transform = `rotate(${tiltLR}deg)
        rotate3d(1,0,0, ${tiltFB * -1}deg)`;
    });

    return () => {};
  }, []); */

  return (
    <div className="etusivu" style={{ height: "100vh" }}>
      <MetaTags>
        <title>Epi.fi</title>
        <meta name="description" content="Portfolio" />
      </MetaTags>
      <Particles style={{ position: "absolute", opacity: "1" }} />
      <Fade timeout={3000}>
        <div>
          <div
            className="header"
            style={{
              display: "flex",
              justifyContent: "space-between"
            }}
          >
            {/*  <LeftBox /> */}
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-around",
              flexDirection: "column",
              marginTop: "10rem"
            }}
          >
            <div
              className="banner"
              //ref={element}
              style={{
                textAlign: "center",
                marginBottom: "0px"
              }}
            >
              <h1>Wiljam Peltomaa</h1>
              <h4>epi.fi</h4>
            </div>
            <div className="social">
              <a
                href="https://github.com/wiltsu"
                target="_blank"
                rel="noopener noreferrer"
                className="link"
              >
                <FontAwesomeIcon
                  color="white"
                  icon={faGithub}
                ></FontAwesomeIcon>
              </a>
              <a
                href="https://www.linkedin.com/in/wiljampeltomaa/"
                target="_blank"
                rel="noopener noreferrer"
                className="link"
              >
                <FontAwesomeIcon
                  color="white"
                  icon={faLinkedin}
                ></FontAwesomeIcon>
              </a>
            </div>
          </div>
        </div>
      </Fade>
    </div>
  );
};

export default LandingPage;
