import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import "./App.css";

import LandingPage from "./pages/LandingPage";
import Villebot from "./pages/Villebot";

const App = () => {
  return (
    <Router>
      <Switch>
        <Route exact path={"/"} component={LandingPage} />
        <Route path={"/villebot"} component={Villebot} />
      </Switch>
    </Router>
  );
};

export default App;
